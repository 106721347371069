
import Vue from 'vue';

export default Vue.extend({
  props: {
    item: {
      type: [String, Object]
    },
    model: {
      type: String
    }
  },
  methods: {
    deleteItem() {
      this.$emit('delete');
    },
    duplicateItem() {
      this.$emit('duplicate');
    },
    editItem() {
      this.$emit('edit', { item: this.item, type: this.model });
    }
  }
});
