
import Vue from 'vue';

export default Vue.extend({
  props: {
    items: {
      type: Array
    },
    name: {
      type: String
    }
  },
  data() {
    return {
      modal: false,
      search: ''
    };
  },
  methods: {
    handleAdd(item: any) {
      this.modal = false;
      this.$emit('add', item);
    }
  },
  computed: {
    tableItems() {
      if (this.search) {
        // filter the search
      }
    }
  }
});
