
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { mapState } from 'vuex';

import EditForm from '@/components/EditForm.vue';

interface IForm extends Vue {
  saveItem: Function
}

@Component({
  components: {
    EditForm
  },
  computed: {
    ...mapState(['edit', 'manage'])
  }
})
export default class Edit extends Vue {
    @Watch('$attrs')
    onAttrChange(val: string, old: string) {
      this.$forceUpdate();
      this.load();
    }

    edit: any;

    manage: any;

    loading = false;

    search = '';

    title() {
      const { id, type } = this.$attrs;

      if (id !== 'new' && !this.loading) {
        return `Editing ${type}: ${this.edit.item.name}`;
      }

      return `Creating New ${type}`;
    }

    getEditItem() {
      return this.edit.item;
    }

    async saveEditItem() {
      // Call action with the new item
      const { item } = this.edit;
      const payload = { model: this.$attrs.type, item };
      let response = { _id: '' };

      if (item._id) {
        response = await this.$store.dispatch('SAVE_MANAGE_ITEM', payload);
        this.load();
      } else {
        response = await this.$store.dispatch('CREATE_MANAGE_ITEM', payload);
        this.load();
        if (response._id) {
          this.$attrs.id = response._id;
        }
      }

      return response;
    }

    async load() {
      const { id, type } = this.$attrs;
      this.loading = true;

      Promise.all([
        this.$store.dispatch('RETRIEVE_MANAGE_SCHEMA', { model: type }),
        this.$store.dispatch('RETRIEVE_MANAGE_SCHEMA_RELATIONSHIPS', { model: type }),
        this.$store.dispatch('RETRIEVE_MANAGE_LIST', { model: type }),
      ]).then(() => {
        if (id === 'new') {
          this.$store.dispatch('CLEAR_EDIT_STATE').then(() => {
            this.loading = false;
          });
        }
  
        this.$store.dispatch('RETRIEVE_EDIT_ITEM', { _id: id, model: type }).then(() => {
          this.loading = false;
        });
      });
    }

    handleKeys(e: any) {
      const ctrlPressed = e.ctrlKey;
      const formRef = this.$refs.form as IForm;

      switch (e.which) {
        case 83:
          if (ctrlPressed) {
            formRef.saveItem();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    }

    mounted() {
      this.load();

      window.addEventListener('keydown', this.handleKeys);
    }
}

