var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(`Add ${_vm.name}`)+" ")])]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"pa-6"},[_c('h1',[_vm._v("Add a "+_vm._s(_vm.name))]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"items":_vm.items,"headers":[
      {
        text: 'Name',
        sortable: false,
        value: 'name',
      },
      {
        text: 'ID',
        sortable: true,
        value: '_id',
      },
    ],"search":_vm.search},on:{"click:row":_vm.handleAdd}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }